const support = {

    blob:
      'FileReader' in window &&
      'Blob' in window &&
      (() => {
        try {
          new Blob(); //tslint:disable-line
          return true;
        } catch (e) {
          return false;
        }
      })(),
};

function parseHeaders(rawHeaders: string) {
    const headers = new Headers();

    const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, ' ');
    preProcessedHeaders
        .split(/\r?\n/)
        .forEach((line) => {
            const parts = line.split(':');
            const key = parts
                .shift()!
                .trim();
            if (key) {
                const value = parts
                    .join(':')
                    .trim();
                headers.append(key, value);
            }
        });
    return headers;
}

const fetchFn: GlobalFetch['fetch'] = (input, init) => {
    let onProgress: XMLHttpRequestUpload['onprogress'] | null = null;

    if (init && init.headers && (init.headers as any).onProgress) {
        onProgress = (init.headers as any).onProgress;
        delete(init.headers as any).onProgress;
    }

    if (onProgress) {
        return new Promise((resolve, reject) => {
            const request = new Request(input!, init);

            if (request.signal && request.signal.aborted) {
                return reject(new DOMException('Aborted', 'AbortError'));
            }

            const xhr = new XMLHttpRequest();

            function abortXhr() {
                xhr.abort();
            }

            xhr.onload = () => {
                const options: any = {
                    status: xhr.status,
                    statusText: xhr.statusText,
                    headers: parseHeaders(xhr.getAllResponseHeaders() || ''),
                };

                options.url = 'responseURL' in xhr
                    ? xhr.responseURL
                    : options
                        .headers
                        .get('X-Request-URL');

                const body = xhr.response || xhr.responseText;

                resolve(new Response(body, options));
            };

            xhr.onerror = () => {
                reject(new TypeError('Network request failed'));
            };

            xhr.ontimeout = () => {
                reject(new TypeError('Network request failed'));
            };

            xhr.onabort = () => {
                reject(new DOMException('Aborted', 'AbortError'));
            };

            if (xhr.upload) {
                xhr.upload.onprogress = onProgress;
            }

            if (request.credentials === 'include') {
                xhr.withCredentials = true;
            } else if (request.credentials === 'omit') {
                xhr.withCredentials = false;
            }

            if ('responseType' in xhr && support.blob) {
                xhr.responseType = 'blob';
            }

            xhr.open(request.method, request.url);

            request
                .headers
                .forEach((value, name) => {
                    if (name === 'content-type' && init && init.body instanceof FormData) {
                        return;
                    }
                    xhr.setRequestHeader(name, value);
                });

            if (request.signal) {
                request
                    .signal
                    .addEventListener('abort', abortXhr);

                xhr.onreadystatechange = () => {
                    // DONE (success or failure)
                    if (xhr.readyState === 4) {
                        request
                            .signal
                            .removeEventListener('abort', abortXhr);
                    }
                };
            }

            if (init && init.body) {
                xhr.send(init.body);
            } else {
                xhr.send();
            }
        });
    }

    return fetch(input, init);
};

export default fetchFn;
